/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


//const baseUrl = 'https://www.ballongahorses.com/'
const baseUrl = 'https://ballonga-horses.local/'


$(document).ready(function () {

    $('.deshabilitar').one('submit', function () {
        $(this).find('input[type="submit"]').attr('disabled', 'disabled');
    });

    $(function () {
        setTimeout(function () {

            if ($(".panel-success").is(":visible")) {
                //you may add animate.css class for fancy fadeout
                $(".panel-success").fadeOut();
            }
            if ($(".panel-danger").is(":visible")) {
                //you may add animate.css class for fancy fadeout
                $(".panel-danger").fadeOut();
            }
        }, 2500)
    });
});


let id;

function borrar(nombre, dato) {
    id = dato;
    $('#myModal').modal('show');
    $('.modal-title').text("¿Seguro que quiere borrar el programa de cria " + nombre + "?");
}

function borrarLaravel() {
    $.ajax({
        url: baseUrl + 'adminPrograma/' + id,
        dataType: 'json',
        type: 'DELETE',
        /*data: {
            access_token: token,
            count: num_photos
        },*/
        success: function (data) {
            console.log(data);
        },
        error: function (data) {
            console.log(data);
        }
    });

    $('#myModal').modal('hide');
}


//Poner fecha en footer
$(document).ready(function () {
    const d = new Date();
    const ano = d.getFullYear();
    $('#ano').text("Ballonga Horses © " + ano);

    $('.btn-go-up').on('click', (e) => {
        window.scrollTo(0, 0)
    });
    $('.btn-go-down').on('click', (e) => {
        window.scrollTo(0, document.body.scrollHeight);
    });


});


//FUNCION MOSTRAR Y OCULTAR MARCAS
$(document).ready(function () {
    $('#divMarcas').hide();
    $('#botonMarcas').click(function () {
        if ($('#divMarcas').is(':visible')) {
            $('#divMarcas').fadeOut(500);
        } else {
            $('#divMarcas').fadeIn(500);
        }
    });

    $('.elegir').click(function () {
        let marca = "";
        marca += $(this).attr('data-marca');
        alert(marca);
        $('#divMarcas').fadeOut(500);

        $('div').data('marca', marca);
        $("div[marca]").fadeOut(500);
    });
});





